import React, { useState, useRef } from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import HCaptcha from '@hcaptcha/react-hcaptcha';
const isGibberish = require('is-gibberish');

// Options for gibberish detector
const OPTIONS = {
    sensitivity: 0.15
  }

const EnterText = (props) => {

    const [enteredText, setEnteredText] = useState("");
    const [captcha, setCaptcha] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [notLoaded, setNotLoaded] = useState(false);
    const [gibberish, setGibberish] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);

    const captchaRef = useRef(null);

    const enterTextHandler = event => {
        event.preventDefault();

        if(enteredText.replaceAll(/\s+/g, '').length < 30 || enteredText.length > 9000) {
            setInvalid(true);
            return;
        } else {
            setInvalid(false);
        }

        if(isGibberish(enteredText, OPTIONS)) {
            setGibberish(true);
            return;
        } else {
            setGibberish(false);
        }
        
        if(!captchaToken) {
            setCaptcha(true);
            return;
        }

        if(props.disable) {
            setNotLoaded(true);
            return;
        }
        
        setInvalid(false);
        setGibberish(false);
        setCaptcha(false);

        props.onSubmit(enteredText, captchaToken);
        
        captchaRef.current.resetCaptcha();
        setCaptchaToken(null);
    }
    
    const textChangeHandler = event => {
        setEnteredText(event.target.value);
    }

    return (
        <form className="enter-text" onSubmit={enterTextHandler}>
            <Container id="textContainer">
                <Row>
                    <Col lg={1} xxs={0}/>
                    <Col lg={10} xxs={12}>
                        <textarea className="textBox" type="text" placeholder="Paste an article, social media post, campaign speech, etc." value={enteredText} onChange={textChangeHandler} /><br/>
                    </Col>
                    <Col lg={1} xxs={0}/>
                </Row>
            </Container>
            <HCaptcha class="h-captcha" sitekey="76c54aa8-fef9-46aa-9fd8-b43c51c773c4" onVerify={setCaptchaToken} ref={captchaRef} />
            <Button variant="primary" type="submit" disabled={props.disable}>ILLUMINATE</Button>
            <h4>Note: by using this tool and clicking ILLUMINATE, you agree to the <a href="/Privacy">Privacy Policy</a></h4>
            {(invalid && <p className="errorText">Please enter text of valid length (30 letters min, 9000 characters max).</p>) 
            || (gibberish && <p className="errorText">Please enter valid language.</p>)
            || (captcha && <p className="errorText">Please complete the Captcha.</p>)
            || (notLoaded && <p className="errorText">Please wait for the existing request to finish!!</p>)}
        </form>
    )
}

export default EnterText;