import { useState, useEffect } from "react";
import TextDisplay from "../components/TextDisplay.js";
import useDocumentTitle from "../components/useDocumentTitle.js";

function Privacy(props) {
    let { article } = props;
    let { loading } = props;

    useDocumentTitle("Privacy Policy - Russell Conjugations");

    return (
        <>
            <div className="container">
                <br/>
                <h2>Privacy Policy/Agreements</h2>
                
                <p>These are the essential things you need to know about this tool before you use it (currently a scaffold
                    for a future, more exhaustive document):</p>
                <p>I do not collect personal information or identifiable usage data for viewers. However,
                    if/when I get Google Adsense running on this website, they will collect all the data here that they collect
                    everywhere else.
                </p>
                <p>By pasting information into this tool, you agree that:
                    <ul>
                        <li>Your pasted text will be sent to OpenAI for analysis (and subject to their privacy policy)</li>
                        <li>Your pasted text will be saved to a database, where I will be able to see it.</li>
                        <li>I may use your pasted text and conjugation results to improve the performance of the model or create new ones.</li>
                    </ul>
                </p>
                <p>By sharing any results from this tool with others, you agree that:
                    <ul>
                        <li>You own the rights to the text you pasted, or</li>
                        <li>You have a fair use purpose for sharing the text.</li>
                    </ul>
                </p>
                <p>
                    It is a risky legal argument to say that the identification of the Russell Conjugations in a text automatically
                    qualifies as commentary/criticism under fair use. It likely works only in cases where the use is "proportional"
                    (i.e., only a short excerpt was pasted, and the highlighted Russell Conjugations contribute something significant
                    and interesting). Please use discretion when sharing results from this site.
                </p>
                <p>
                    I claim copyrights only for the finetuned ChatGPT model itself, and the Russell Conjugations I used to make it.
                    Specific results are owned by the original creators of the text.
                </p>
                </div>
            <br />
            <hr />
            <TextDisplay className="article" text={article} loading={loading} />
        </>
    );
}

export default Privacy;