import { useState, useEffect } from "react";
import TextDisplay from "../components/TextDisplay.js";
import useDocumentTitle from "../components/useDocumentTitle.js";

function Examples(props) {

    useDocumentTitle("Examples - Russell Conjugations");

    return (
        <div className="container">
            <br/>
            <h2>Examples</h2>
            <p>Here are some cool examples that have been analyzed with the model and edited if necessary:</p>
            <ul>
                <li>
                    <a href="/conj/UnbiasedJesusReporting">Biblically accurate Jesus reporting</a>
                </li>
                <li>
                    <a href="/conj/TrumpPoliticalPrisoner">Trump's announcement that he's been criminally convicted</a>
                </li>
                <li>
                    <a href="/conj/BidenDescribesMAGA">Excerpt from Biden's Philadelphia speech</a>
                </li>
                <li>
                    <a href="/conj/Orlando">Definitely a biased perspective... but no Russell Conjugations</a>
                </li>
                <li>
                    <a href="/conj/PoliticalList">Some interesting/common Conjugations in politics</a>
                </li>
            </ul>
            <p>More to come as the model improves and feedback is recieved!</p>
            <br />
        </div>
    );
}

export default Examples;