import { Container, Placeholder, Col, Row } from 'react-bootstrap';
import { useEffect } from "react";
import DOMPurify from 'dompurify';

const TextDisplay = props => {

  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  function getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  }

  function getCanvasFont(el = document.body) {
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '16px';
    const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
    
    return `${fontWeight} ${fontSize} ${fontFamily}`;
  }

    useEffect(() => {
        document.querySelectorAll('[data-illuminate=tooltip]').forEach(function (tooltip) {

            tooltip.addEventListener('mouseenter', function (e) {
              const tooltipText = tooltip.querySelector('[data-illuminate=tooltiptext]');
            
              var elementRect = tooltipText.getBoundingClientRect();
              const parentRect = tooltip.getBoundingClientRect();
              const grandparentRect = tooltipText.parentElement.parentElement.getBoundingClientRect();

              const actualConjugations = tooltipText.innerHTML.split('<hr>');

              /*
              const conjugationWords = tooltipText.innerHTML.split(' ');

              var longestWordWidth = conjugationWords.reduce(function (a, b) {
                var widthA = getTextWidth(a, getCanvasFont(tooltipText));
                var widthB = getTextWidth(b, getCanvasFont(tooltipText));

                return widthA > widthB ? widthA : widthB;
              });
              */
              
              var textWidth = actualConjugations.reduce(function (a, b) {
                var widthA = getTextWidth(a, getCanvasFont(tooltipText));
                var widthB = getTextWidth(b, getCanvasFont(tooltipText));

                return widthA > widthB ? widthA : widthB;
              });

              if(actualConjugations.length == 1) {
                textWidth = getTextWidth(textWidth, getCanvasFont(tooltipText));
              }

              //console.log(textWidth);
              //console.log(parentRect.width);
              //console.log(grandparentRect.width);

              
              if(parentRect.width > textWidth + 15 && parentRect.width + 100 < grandparentRect.width) {
                tooltipText.style.width = parentRect.width + "px";
              } else if (parentRect.width > textWidth || textWidth < 200) {
                tooltipText.style.width = textWidth + 15 + "px";
              } else if (parentRect.width < 200) {
                tooltipText.style.width = "200px";
              } else {
                tooltipText.style.width = '100%';
              }

              const x = e.clientX - parentRect.left;
              const y = e.clientY - parentRect.top;

              const fontSize = parseFloat(window.getComputedStyle(tooltip, null).getPropertyValue('font-size'));

              /*
              console.log(x);
              console.log(parentRect.width);
              console.log(y);
              console.log(parentRect.height);
              */

              if(parentRect.height > fontSize * 2) {
                if(y > parentRect.height / 2 + 2.5 || (y > parentRect.height / 2 - 2.5 && x < parentRect.width / 2)) {
                    tooltipText.style.left = "auto";
                    tooltipText.style.right = "auto";
                    tooltipText.style.bottom = parentRect.height / 2 + `px`;
                }
              }

              elementRect = tooltipText.getBoundingClientRect();
              
              if(elementRect.right > grandparentRect.right) {
                const overflow = elementRect.right- grandparentRect.right;
                tooltipText.style.left = `${-overflow}px`;
              }
            })
            tooltip.addEventListener('mouseleave', function (e) {
              const tooltipText = tooltip.querySelector('[data-illuminate=tooltiptext]');
              tooltipText.style.left = `0`;
              tooltipText.style.bottom = `100%`;
            })
          });
    }, [])

    // Use placeholder for text while waiting for it. Also disable the button during this time.
    return (
        <Container id="bigContainer">
            {props.loading ?
            <>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
            </>
            :
            <Row>
                <Col lg={1} xxs={0}/>
                <Col lg={10} xxs={12}>
                    {props.text.map((p, i) => {
                        return <p key={`p_${i}`} data-illuminate={`p_${i}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(p)}}></p>
                    })}
                </Col>
                <Col lg={1} xxs={0}/>
            </Row>
            }
            {props.conjId === "UnbiasedJesusReporting" ?
                <a href="https://gist.github.com/k-bx/bd1e6886dbea7005321f0e8041ad005e">Original text source<br /></a> :
                <></>
            }
            <br />
            <a href="/examples">View more examples</a>
        </Container>
    );
}

export default TextDisplay;